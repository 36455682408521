<script>
  import Modular from '../shared/modular.svelte';
  import Spinner from '../shared/spinner.svelte';

  import { User } from '../shared/user';
  import { Uploader } from '../shared/uploader';

  import { setFocus } from '../shared/utils';
  
  let logged_out = window.User.name === undefined;
  let size,quota,quota_pct,name;
  let sponsor = true;

  function updateUserInfo(u){
    if (u.name===undefined) return;
    window.User = u;
    size = parseInt(u.cached_size)||0;
    quota = parseInt(u.quota)||1;
    quota_pct = Math.round(100*size/quota);
    name = u.name||"";
    logged_out = false;
  }
  updateUserInfo(window.User);
  User.onlogin(u=>updateUserInfo(u));
  User.onlogout(()=>logged_out = true);

  Uploader.then(m=>{
    if (!m.hasOwnProperty('user')) return;
    let user_data = null;
    try {
        user_data = JSON.parse(m.user);
    } catch {
        user_data = m.user;
    }
    updateUserInfo(user_data);
  });

  function logout() {
    Uploader.request_json({}, "/logout", 'DELETE');
    logged_out = true;
  }

  let modular = null;

  let sending = false;
  let error = "";
  function post(o,u){
      if (sending) {
          error = "Already sending a login request";
          return;
      }
      sending = true;
      Uploader.request_json(o,u,"POST")
        .then(u=> sending = false )
        .success( u => {
            error = "";
            modular = null; 
        }).error( u => {
            error = u.error||u.message;
            console.log("error",u);
        });
  }
  function login(){
    const n = document.querySelector("#login_name").value;
    const p = document.querySelector("#login_pw").value;
    post({session: {"name": n, "password": p}},"/login");
  }
  function signup(){
    const n = document.querySelector("#signup_name").value;
    const p = document.querySelector("#signup_pw").value;
    const pc = document.querySelector("#signup_pwc").value;
    const e = document.querySelector("#signup_email").value;
    post({user: {name: n, password: p, password_confirmation: pc, email: e}},"/users");
  }
  function retrievePW(){
    const e = document.querySelector("#pr_email").value;
    post({email: e},"/password_retrieval");
  }
</script>

<header>
  <div id='Root' style='display:none;'></div>
  <div id='user' style='display:none;'></div>

  {#if logged_out}
    <a id='home' class='button' href="/" help="Browse projects">Home</a>
  {:else}
    <a id='home' class='button' href="/" help="Browse projects">Projects</a>
    <a id='percent' class='loggedin' help="Space used by your projects" href="/help#quota" target="_blank">
      <div 
        class='percent'
        style='background: linear-gradient(to right, rgb(0,200,0), rgb({quota_pct*2},{200-quota_pct*2},0) {quota_pct}%, transparent {quota_pct}%)'
        >
        {Math.round(size/10000)/100}/{quota/1000000}MB ({quota_pct}%)
      </div>
    </a>
  {/if}

  <div id='topbar'> </div>

  {#if logged_out}
    <div id='signup' class='loggedout modular-link button' target='signup' on:click={()=>modular='signup'}>Sign up</div>
    <div id='login' class='loggedout button' target='login' on:click={()=>modular='login'}>Log in</div>
  {:else}  
    <a id='profile' class='loggedin button' href='/profile' help="Profile">{name}</a>
    <div id='logout' class='loggedin button'><div class='submit' on:click={logout}>Log Out</div></div>
  {/if}

  <a id='doc' class='button' href='https://doc.pcibex.net/' target='_blank' help="PCIbex Documentation">Doc</a>
  <a id='support' class='button' href='/help' target='_blank' help="Help on the Farm's interface">Support</a>
</header>


<div id='main-container'>
  <div id='message-container'></div>
</div>

{#if sponsor}
<div id='sponsor'>
  <a href="https://mindcore.sas.upenn.edu/" target="_blank">Generously funded by <span id='mindcore'>MindCORE</span></a>
  <span id='close' on:click={()=>sponsor=false}>close</span>
</div>
{/if}

{#if modular != null}
  <Modular close={()=>modular=null}>
    {#if modular == 'login'}
      <h1>Log in</h1>
      <p>
          <label for="login_name">Username </label>
          <input id="login_name" name="login_name" type="text" on:input={()=>error=""} use:setFocus on:keydown={e=>e.key=="Enter"&&login()} />
      </p>
      <p>
          <label for="login_pw">Password </label>
          <input id="login_pw" name="login_pw" type="password" on:input={()=>error=""} on:keydown={e=>e.key=="Enter"&&login()} />
      </p>
      <p class='error'> {error} </p>
      <p>
        {#if sending}
          <Spinner />
        {:else}
          <button class="submit" on:click={login}>Log in</button>
        {/if}
      </p>
      <p class='modular-link' on:click={e=>modular='signup'}>or Sign up for a new account</p>
      <p class='modular-link' on:click={e=>modular='passwordretrieval'}>Forgot your password?</p>
    {:else if modular == 'passwordretrieval'}
      <h1>Password retrieval</h1>
      Enter your email address below and we will send you a link to change your password
      <p>
          <label for="pr_email">Email address</label>
          <input id="pr_email" name="pr_email" type="text" use:setFocus on:input={()=>error=""} on:keydown={e=>e.key=="Enter"&&retrievePW()}/>
      </p>
      <p class='error'> {error} </p>
      <p>
        {#if sending}
          <Spinner />
        {:else}
          <button class="submit" on:click={retrievePW}>Send email</button>
        {/if}
      </p>
      <p class='modular-link' on:click={e=>modular='login'}>Go back to Login</p>
    {:else if modular == 'signup'}
      <h1>Sign up</h1>
      <p>
          <label for="signup_name">Username </label>
          <input id="signup_name" name="signup_name" type="text" use:setFocus on:input={()=>error=""} on:keydown={e=>e.key=="Enter"&&signup()} />
      </p>
      <p>
          <label for="signup_pw">Password </label>
          <input id="signup_pw" name="signup_pw" type="password" on:input={()=>error=""} on:keydown={e=>e.key=="Enter"&&signup()} />
      </p>
      <p>
          <label for="signup_pwc">Password confirmation </label>
          <input id="signup_pwc" name="signup_pwc" type="password" on:input={()=>error=""} on:keydown={e=>e.key=="Enter"&&signup()} />
      </p>
      <p>
          <label for="signup_email">Email address<span class='why' help="You will receive emails: (1) if you forget your password, (2) to notify you of upcoming updates and/or expirations">why</span> </label>
          <input id="signup_email" name="signup_email" type="text" on:input={()=>error=""} on:keydown={e=>e.key=="Enter"&&signup()} />
      </p>
      <p class='error'> {error} </p>
      <p>
        {#if sending}
          <Spinner />
        {:else}
          <button class="submit" on:click={signup}>Sign up</button>
        {/if}
      </p>
      <p class='modular-link' on:click={e=>modular='login'}>or Log in with an existing account</p>
    {/if}
  </Modular>
{/if}
