import { Message } from '../shared/modular_messages';

document.addEventListener('DOMContentLoaded',e=>{
    console.log("modular_messages", window.flash_messages);
    for (let i = 0; i < window.flash_messages.length; i++){
        let m = window.flash_messages[i];
        console.log("flash message ",i,m);
        if (m instanceof Array && m.length==2 && m[1].match(/[^\s]/)){
            if (m[0] == "error")
                console.log("throwing error message",i,m,Message.error( m[1] ));
            else if (m[0] == "danger")
                Message.warning( m[1] );
            else
                Message.log( m[1] , m[0] );            
        }
    }
});
