
import "@rails/ujs" // Autostarts
// import Turbolinks from "turbolinks"
// import ActiveStorage from "@rails/activestorage"
// Turbolinks.start()
// ActiveStorage.start()

import '../application/hover_titles.js';
import '../application/show_flash_messages.js';
// import '../shared/modular_popin.js';
import App from '../application/application.svelte';

document.addEventListener('DOMContentLoaded', () => {
  const app = new App({
      target: document.body
  });
});
